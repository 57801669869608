import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { categoryBgColors } from '../vars/categoryColors';
import HotspotContentThumbnail from './HotspotContentThumbnail';
import {
  arrowIcon,
  drawerClosed,
  drawerGrid,
  drawerItem,
  drawerTitle,
  hotspotDrawerContainer,
  mapItem,
  thumb,
} from './HotspotDrawer.module.scss';
import ArrowIcon from './icons/ArrowIcon';
import TranslatedText from './TranslatedText';

const HotspotDrawer = ({
  activeCategory,
  hotspots,
  activeHotspot,
  setActiveHotspot,
  clickedScreen,
}) => {
  // TODO does this state need to be higher so any other elements can control it?
  const [drawerOpen, setDrawerOpen] = useState(false);
  // console.log(hotspots);

  const { allStrapiOsd, strapiInterface } = useStaticQuery(graphql`
    query HotspotDrawerQuery {
      allStrapiOsd {
        nodes {
          categories {
            category_id
            title
            drawer_label
          }
          localizations {
            data {
              attributes {
                locale
                categories {
                  category_id
                  title
                  drawer_label
                }
              }
            }
          }
        }
      }
      strapiInterface {
        select_category
        localizations {
          data {
            attributes {
              locale
              select_category
            }
          }
        }
      }
    }
  `);

  const nodeNumber = allStrapiOsd.nodes.findIndex((node) =>
    node.categories.some((category) => category.category_id === activeCategory)
  );
  const categoryNumber =
    nodeNumber >= 0
      ? allStrapiOsd.nodes[nodeNumber].categories.findIndex(
          (category) => category.category_id === activeCategory
        )
      : null;

  return (
    <div
      className={`${hotspotDrawerContainer}  ${
        drawerOpen ? '' : drawerClosed
      } ${
        {
          leftScreen: 'navLeft',
          centerScreen: 'navCenter',
          rightScreen: 'navRight',
        }[clickedScreen]
      }`}
      // style={{
      //   margin: {
      //     leftScreen: '0 calc(100vw - 1080px) 0 0',
      //     centerScreen: '0 calc(50vw - 540px)',
      //     rightScreen: '0 0 0 calc(100vw - 1080px)',
      //   }[clickedScreen],
      // }}
    >
      <div
        className={`${drawerTitle} ${categoryBgColors[activeCategory]}`}
        role="button"
        tabIndex="0"
        onClick={() => setDrawerOpen(!drawerOpen)}
        onKeyDown={(e) =>
          (e.key === 'Enter' || e.key === ' ') && setDrawerOpen(!drawerOpen)
        }
      >
        <h2 className={`h6`}>
          {activeCategory === 'NONE' ? (
            <TranslatedText data={strapiInterface} name="select_category" />
          ) : (
            <TranslatedText
              name={`categories.${categoryNumber}.drawer_label`}
              data={allStrapiOsd.nodes[nodeNumber]}
            />
          )}
        </h2>
        {/* FIXME arrow needs to rotate */}
        <div className={`${arrowIcon}`}>
          <ArrowIcon />
        </div>
      </div>
      <ul className={`${drawerGrid} ${categoryBgColors[activeCategory]}`}>
        {hotspots.map(
          (hotspot, index) =>
            (hotspot.category === activeCategory ||
              activeCategory === 'ALL_MURAL' ||
              activeCategory === 'ALL_MAP') && (
              <li key={index}>
                <button
                  type="button"
                  className={`${drawerItem} ${
                    hotspot.osd.osd_id === 'map' ? mapItem : ''
                  } listItemTouchwall`}
                  onClick={() => setActiveHotspot(hotspot.hotspot_id)}
                  onKeyDown={(e) =>
                    (e.key === 'Enter' || e.key === ' ') &&
                    setActiveHotspot(hotspot.hotspot_id)
                  }
                  tabIndex={drawerOpen ? '0' : '-1'}
                >
                  {hotspot.osd.osd_id === 'map' && (
                    <span className={`listNumberTouchwall textRight`}>
                      {index + 1}
                    </span>
                  )}
                  <span className={`${thumb}`}>
                    <HotspotContentThumbnail
                      osdId={hotspot.osd.osd_id}
                      muralInsert={hotspot.mural_insert}
                      thumbnail={hotspot.thumbnail}
                      media={hotspot.media}
                    />
                  </span>
                  <TranslatedText data={hotspot} name="title" />
                </button>
              </li>
            )
        )}
      </ul>
    </div>
  );
};
export default HotspotDrawer;
