import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import React, { useEffect, useRef, useState } from 'react';
import HotspotCard from './HotspotCard';

import { graphql, useStaticQuery } from 'gatsby';
import { noCardsMessage, scrollContainer } from './HotspotCardList.module.scss';
import TranslatedText from './TranslatedText';

gsap.registerPlugin(ScrollToPlugin);

const HotspotCardList = ({
  hotspots,
  activeHotspot,
  setActiveHotspot,
  activeCategory,
}) => {
  const { strapiInterface } = useStaticQuery(graphql`
    query HotspotCardListQuery {
      strapiInterface {
        select_category
        localizations {
          data {
            attributes {
              locale
              select_category
            }
          }
        }
      }
    }
  `);

  const scrollContainerRef = useRef();

  // Scroll to the active hotspot
  useEffect(() => {
    if (activeHotspot)
      gsap.to(scrollContainerRef.current, {
        duration: 2,
        scrollTo: { y: `#card-${activeHotspot}`, offsetY: 10 },
      });
  }, [activeHotspot]);

  const selectEl = gsap.utils.selector(scrollContainerRef);
  const visibleCardsRef = useRef();
  const [visibleCards, setVisibleCards] = useState([]);
  useEffect(() => {
    // console.log(selectEl('.visible'));
    // visibleCardsRef.current = selectEl('.visible');
    setVisibleCards(selectEl('.visible'));
  }, [activeCategory]);

  return (
    <ul className={`${scrollContainer}`} ref={scrollContainerRef}>
      {activeCategory === 'NONE' && (
        <li className={`${noCardsMessage}`}>
          <TranslatedText data={strapiInterface} name="select_category" />
        </li>
      )}

      {hotspots.map((hotspot, index) => (
        <HotspotCard
          key={hotspot.hotspot_id}
          hotspot={hotspot}
          activeHotspot={activeHotspot}
          setActiveHotspot={setActiveHotspot}
          activeCategory={activeCategory}
          index={index}
          // visibleIndex={visibleCardsRef.current?.findIndex(
          //   (card) => card.id === hotspot.hotspot_id
          // )}
          // visibleCards={visibleCardsRef.current}
          // visibleCards={visibleCards}
          visibleIndex={visibleCards.findIndex(
            (card) => card.id === `card-${hotspot.hotspot_id}`
          )}
        />
      ))}
    </ul>
  );
};
export default HotspotCardList;
