import { graphql, useStaticQuery } from 'gatsby';
import React, { useRef } from 'react';
import { categoryBgColors } from '../vars/categoryColors';
import HotspotContentMedia from './HotspotContentMedia';
import HotspotContentText from './HotspotContentText';
import {
  closeButton,
  mediaContainer,
  popupContainer,
  textContainer,
  titleWrapper,
} from './HotspotPopup.module.scss';
import CloseIcon from './icons/CloseIcon';
import TranslatedText from './TranslatedText';

const HotspotPopup = ({ hotspot, setActiveHotspot, index }) => {
  const {
    hotspot_id,
    category,
    mural_insert,
    media,
    osd,
    childrenStrapiHotspotCreditTextnode,
  } = hotspot;

  const { site } = useStaticQuery(
    graphql`
      query HotspotSiteQuery {
        site {
          pathPrefix
        }
      }
    `
  );

  const popupRef = useRef();

  return (
    <div
      id={`popup-${hotspot_id}`}
      className={`hotspotPopup ${popupContainer} ${categoryBgColors[category]}`}
      ref={popupRef}
      tabIndex={-1}
    >
      <button
        type="button"
        aria-label="Close Popup"
        onClick={() => setActiveHotspot('')}
        className={`${closeButton}`}
      >
        <CloseIcon />
      </button>
      <div className={`${mediaContainer}`}>
        <HotspotContentMedia
          media={media}
          site={site}
          muralInsert={mural_insert}
          index={index}
        />
      </div>
      <div className={`${textContainer}`}>
        <h2 className={`${titleWrapper}`}>
          <span className={`popupNumber block`}>
            {osd.osd_id === 'map' && index + 1}
          </span>
          <TranslatedText data={hotspot} name="title" />
        </h2>
        <HotspotContentText
          hotspot={hotspot}
          credit={childrenStrapiHotspotCreditTextnode[0]?.credit}
        />
      </div>
    </div>
  );
};
export default HotspotPopup;
